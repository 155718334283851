body {
  background-image: url("./assets/bg.jpeg");
  font-family: 'Unica One', sans-serif;
  background-size: cover; /* Cover the entire viewport */
  background-position: center; /* Center the background image */
  background-repeat: no-repeat; 
}

.bgCard {
  background-color: rgba(72, 73, 76, 0.6);
}

.bgHeader {
  background: rgb(128,54,43);
  background: radial-gradient(circle, rgba(128,54,43,1) 50%, rgba(36,36,37,1) 100%);
}

.buttonTag {
  display: inline-block;
  text-align: center;
  vertical-align: middle;
  padding: 12px 24px;
  border: 1px solid #a12727;
  border-radius: 8px;
  background: rgba(128,54,43,1);
  background: -webkit-gradient(linear, left top, left bottom, from(rgba(128,54,43,1)), to(#992727));
  background: -moz-linear-gradient(top, rgba(128,54,43,1), #992727);
  background: linear-gradient(to bottom, rgba(128,54,43,1), #992727);
  -webkit-box-shadow: #ff5959 0px 0px 40px 0px;
  -moz-box-shadow: #ff5959 0px 0px 40px 0px;
  box-shadow: #ff5959 0px 0px 40px 0px;
  text-shadow: #591717 1px 1px 1px;
  font: normal normal bold 24px arial;
  color: #ffffff;
  text-decoration: none;
}
.buttonTag:hover,
.buttonTag:focus {
  background: #ff5959;
  background: -webkit-gradient(linear, left top, left bottom, from(#ff5959), to(#b62f2f));
  background: -moz-linear-gradient(top, #ff5959, #b62f2f);
  background: linear-gradient(to bottom, #ff5959, #b62f2f);
  color: #ffffff;
  text-decoration: none;
}
.buttonTag:active {
  background: #982727;
  background: -webkit-gradient(linear, left top, left bottom, from(#982727), to(#982727));
  background: -moz-linear-gradient(top, #982727, #982727);
  background: linear-gradient(to bottom, #982727, #982727);
}
.buttonTag:before{
  content:  "\0000a0";
  display: inline-block;
  height: 24px;
  width: 24px;
  line-height: 24px;
  margin: 0 4px -6px -4px;
  position: relative;
  top: -1px;
  left: -3px;
  background: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAACXBIWXMAAA7EAAAOxAGVKw4bAAABe0lEQVRIie3UsUpcURAG4C8iIotYiUgewNJKUmoCqSxjCgsJYm1lJXZiLZIH8AGChViJbJqt0gR8giAiWAoiCouIFnOik3Wv7t2QVP4wcJk555//zPnv4RX/CGto4zbFXYmTvPBNH+Sf8A3f8TPlb/AB7/vkBVO4xA80utQ3xGkeMFCDfAL7OMc8rnvZNNgj+bAYyzhmcVaxro7gP7Ajjr6QiNax2LFuU9zFi8hKVoU7NlJtu+TW07oGDoS7KjGKPfwSbpkrG3bFODP51yTkrbj4G2Hhrmig5dHPbVzgCCOFbKvUthP5tPD+ZRFVieVEnmOlQ3kmX8AVjoWFn8W7orqzQVvYM5MPevR8S1i4J3yuaHJXmgyIce2W3I6wcC0sVjRoi3Edle/VusSKusOKBr/jQrirJ+Q/uSEs+vGFPcP6GMsQmp5XnuNYzWdhpoKoiS+eXvxe3ROM4bQL+UipZ3e1xB9fG5OpSdPT934aS13ytTAmxjX0NyT/FfeqxYOL7vUOEwAAAABJRU5ErkJggg==") no-repeat left center transparent;
  background-size: 100% 100%;
}



.buttonTag2 {
  display: inline-block;
  text-align: center;
  vertical-align: middle;
  padding: 12px 24px;
  border: 1px solid #a12727;
  border-radius: 8px;
  background: rgba(128,54,43,1);
  background: -webkit-gradient(linear, left top, left bottom, from(rgba(128,54,43,1)), to(#992727));
  background: -moz-linear-gradient(top, rgba(128,54,43,1), #992727);
  background: linear-gradient(to bottom, rgba(128,54,43,1), #992727);
  -webkit-box-shadow: #ff5959 0px 0px 40px 0px;
  -moz-box-shadow: #ff5959 0px 0px 40px 0px;
  box-shadow: #ff5959 0px 0px 40px 0px;
  text-shadow: #591717 1px 1px 1px;
  font: normal normal bold 24px arial;
  color: #ffffff;
  text-decoration: none;
}
.buttonTag2:hover,
.buttonTag2:focus {
  background: #ff5959;
  background: -webkit-gradient(linear, left top, left bottom, from(#ff5959), to(#b62f2f));
  background: -moz-linear-gradient(top, #ff5959, #b62f2f);
  background: linear-gradient(to bottom, #ff5959, #b62f2f);
  color: #ffffff;
  text-decoration: none;
}
.buttonTag2:active {
  background: #982727;
  background: -webkit-gradient(linear, left top, left bottom, from(#982727), to(#982727));
  background: -moz-linear-gradient(top, #982727, #982727);
  background: linear-gradient(to bottom, #982727, #982727);
}
.buttonTag2:before{
  content:  "\0000a0";
  display: inline-block;
  height: 24px;
  width: 24px;
  line-height: 24px;
  margin: 0 4px -6px -4px;
  position: relative;
  top: -1px;
  left: -3px;
  background: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAACXBIWXMAAA7EAAAOxAGVKw4bAAABHUlEQVRIicXVsUoDQRCA4c/zkHAEKxEfQ6wsLPIKNhZiZRGCiC8TrMTKMs8iIj5AEEtLCSIaY3F7uhzJBc0eDgx3szs7/+7s7iwty3pDX4E1TNuCT/CCMUY4QSclYDZHn3CELBVggl0McBuBhshTAYpg5+jjNfRdpgZU0gvtM2W6kgMoUzbD44L+lQE57oLPcVOQ+mnIcICNJfAPXIX/w2jsADuLBnVxE2Z16mcFW8pV1HUv+IyxiYtgP2C7HrzAM96DU/WtIIs09nmL7PsKEqfoszkrq0sH18ra89sUdXEexn7Pfp5k2FductMpgrPgM4ra+ho2uS5JjulfAa1etJ6WSkW92A1XCR4D6uV6KmG5bvXBaf3JLCwvev8vX6XyckpLqlm5AAAAAElFTkSuQmCC") no-repeat left center transparent;
  background-size: 100% 100%;
}


.buttonTag3 {
  display: inline-block;
  text-align: center;
  vertical-align: middle;
  padding: 12px 24px;
  border: 1px solid #a12727;
  border-radius: 8px;
  background: rgba(128,54,43,1);
  background: -webkit-gradient(linear, left top, left bottom, from(rgba(128,54,43,1)), to(#992727));
  background: -moz-linear-gradient(top, rgba(128,54,43,1), #992727);
  background: linear-gradient(to bottom, rgba(128,54,43,1), #992727);
  -webkit-box-shadow: #ff5959 0px 0px 40px 0px;
  -moz-box-shadow: #ff5959 0px 0px 40px 0px;
  box-shadow: #ff5959 0px 0px 40px 0px;
  text-shadow: #591717 1px 1px 1px;
  font: normal normal bold 24px arial;
  color: #ffffff;
  text-decoration: none;
}
.buttonTag3:hover,
.buttonTag3:focus {
  background: #ff5959;
  background: -webkit-gradient(linear, left top, left bottom, from(#ff5959), to(#b62f2f));
  background: -moz-linear-gradient(top, #ff5959, #b62f2f);
  background: linear-gradient(to bottom, #ff5959, #b62f2f);
  color: #ffffff;
  text-decoration: none;
}
.buttonTag3:active {
  background: #982727;
  background: -webkit-gradient(linear, left top, left bottom, from(#982727), to(#982727));
  background: -moz-linear-gradient(top, #982727, #982727);
  background: linear-gradient(to bottom, #982727, #982727);
}
.buttonTag3:before{
  content:  "\0000a0";
  display: inline-block;
  height: 24px;
  width: 24px;
  line-height: 24px;
  margin: 0 4px -6px -4px;
  position: relative;
  top: -1px;
  left: -3px;
  background: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAACXBIWXMAAA7EAAAOxAGVKw4bAAAB7klEQVRIib3WvWsVQRQF8J/xIUFFEEQUJYVYWVhYWYiNgo2VlVqIlaV/gWAlIpJCRFRS2IgWfiUREUGJBGKhRQgKikFJjJjCj2j8iJhnYjH3kXHdvGwiemDY++6dOWf2zT27yz/GogpzVqHWpD6ObwsV2Ip76MRO3MdmvMMPtOENdqBeRtDShHwTrmFpkLVkJHVMRW47Ts7GtXgW0X24gtVB3o9nmMQrfMBHrMTauNN16In5pViCPejDdDba8QJfS8ZxjGVzB3EguP4gHy4Qf45bf1TIF+ccwUghP1wm0hPFCZyK8bawcDD+rjw3gfOxmU+R62mQ5gdzK6416QAPSy2a47vUQTlacQgrstydMoGrQVwzS8vNURvPRK6XCQyhN+K2+F1V4AuWR/wQz8sE4HRcd0vmqirQHWugIy8UBbrxRDJXY2dzCUzhNdZLrr7UTKCOoxHvxcUKAr3YFnG7wnOpzN6duIs1QTjURKCOB5KTX+JcCV8pNkgmmpSMNCn19xgumPFAh+TyaTNnUBkHY+EITuBniHZEvk96GE6H6LzRgjNB0C912ATOYiCrPfa7yeaFGrqCaADHsD8jH8XGhZI30IrbGeHNiN9jy9+S5yKXzRzuaFXyshdOGeq4Ib1il2EXns57mxXR7APg/+MXtvyiBhB0luUAAAAASUVORK5CYII=") no-repeat left center transparent;
  background-size: 100% 100%;
}

